import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import "../assets/css/styles.css"

gsap.registerPlugin(ScrollTrigger)

export default function ScrollSection() {
  const panels = useRef([])
  const panelsContainer = useRef()

  const createPanelsRefs = (panel, index) => {
    panels.current[index] = panel
  }

  useEffect(() => {
    const totalPanels = panels.current.length

    gsap.to(panels.current, {
      xPercent: -100 * (totalPanels - 1),
      ease: "none",
      scrollTrigger: {
        trigger: panelsContainer.current,
        pin: true,
        scrub: 3,
        snap: 1 / (totalPanels - 1),
        // base vertical scrolling on how wide the container is so it feels more natural.
        end: () => "+=" + panelsContainer.current.offsetWidth,
      },
    })
  }, [])

  return (
    <>
      <div className="cust-container" ref={panelsContainer}>
        <div
          className="description panel blue"
          ref={e => createPanelsRefs(e, 0)}
        >
          <div className="row">
            <div className="col-md-6">
              <h1>Horizontal snapping sections (simple)</h1>
            </div>
            <div className="col-md-6">
              <p>
                Scroll vertically to scrub the horizontal animation. It also
                dynamically snaps to the sections in an organic way based on the
                velocity. The snapping occurs based on the natural ending
                position after momentum is applied, not a simplistic "wherever
                it is when the user stops".
              </p>
            </div>
          </div>
        </div>
        <section className="panel red" ref={e => createPanelsRefs(e, 1)}>
          ONE
        </section>
        <section className="panel orange" ref={e => createPanelsRefs(e, 2)}>
          TWO
        </section>
        <section className="panel purple" ref={e => createPanelsRefs(e, 3)}>
          THREE
        </section>
        <section className="panel green" ref={e => createPanelsRefs(e, 4)}>
          FOUR
        </section>
        <section className="panel gray" ref={e => createPanelsRefs(e, 5)}>
          FIVE
        </section>
      </div>
    </>
  )
}
