import React from "react"
import Layout from "@/components/layout"
import HeaderOne from "@/components/header-one"
import PageBanner from "@/components/page-banner"
import Footer from "@/components/footer-one"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import ScrollSection from "@/components/scrolling-sections"

const ScrollSec = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Privacy">
          <HeaderOne />
          <PageBanner title="Privacy Policy" name="Privacy" />
          <ScrollSection />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default ScrollSec
